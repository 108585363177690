/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import 'gatsby-remark-vscode/styles.css';
import { wrapRootElement as wrap } from './rootWrapper';

export const wrapRootElement = wrap;